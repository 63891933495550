var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[_c('a-row',{attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{span: 12, offset: 0},"xl":{span: 6, offset: 2}}},[_c('h1',{staticClass:"mb-15"},[_vm._v("ورود به سامانه")]),_c('h5',{staticClass:"font-regular text-muted"},[_vm._v("جهت ورود آدرس ایمیل و رمز عبور خود را وارد کنید")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"آدرس ایمیل","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{ rules: [{ required: true, message: 'لطفا ایمیل خود را وارد کنید!' }] } ]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'لطفا ایمیل خود را وارد کنید!' }] },\n\t\t\t\t\t\t]"}],staticClass:"ltr",attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"رمز عبور","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'password',
							{
								rules:[
									{
										required: true, message: 'لطفا رمز خود را وارد کنید!'
									}
								]
							} ]),expression:"[\n\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules:[\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true, message: 'لطفا رمز خود را وارد کنید!'\n\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t]\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],staticClass:"ltr",attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-switch',{model:{value:(_vm.rememberMe),callback:function ($$v) {_vm.rememberMe=$$v},expression:"rememberMe"}}),_vm._v(" مرا به یاد بسپار ")],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" ورود ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted"},[_vm._v("هنوز حساب کاربری ندارید؟ "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-up"}},[_vm._v("ثبت نام کنید")])],1)],1),_c('a-col',{staticClass:"col-img",attrs:{"span":24,"md":12,"lg":12,"xl":12}},[_c('img',{attrs:{"src":"images/img-signin.png","alt":""}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }