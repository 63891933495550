<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h1 class="mb-15">ورود به سامانه</h1>
				<h5 class="font-regular text-muted">جهت ورود آدرس ایمیل و رمز عبور خود را وارد کنید</h5>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="آدرس ایمیل" :colon="false">
						<a-input
							class='ltr' 
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'لطفا ایمیل خود را وارد کنید!' }] },
						]" placeholder="Email" />
					</a-form-item>
					<a-form-item class="mb-5" label="رمز عبور" :colon="false">
						<a-input
							class='ltr'
						v-decorator="[
							'password',
							{
								rules:[
									{
										required: true, message: 'لطفا رمز خود را وارد کنید!'
									}
								]
							},
						]" type="password" placeholder="Password" />
					</a-form-item>
					<a-form-item class="mb-10">
    					<a-switch v-model="rememberMe" /> مرا به یاد بسپار
					</a-form-item>
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							ورود
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

				<p class="font-semibold text-muted">هنوز حساب کاربری ندارید؟ <router-link to="/sign-up" class="font-bold text-dark">ثبت نام کنید</router-link></p>
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.png" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
    import {  mapActions } from 'vuex'
	export default ({
		data() {
			return {
				email: null,
				password: null,

				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				rememberMe: true,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			...mapActions('login', {
                login: 'login'
            }),
			reset() {
                this.$refs.form.reset();
            },
            doLogin() {
				const email = this.form.getFieldValue('email');
				const password = this.form.getFieldValue('password');
				if(email != "" && password != "") {
                    this.login({ email, password });
                }
				else
				{
                    alert('Please fill the text!');
                }
            },
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						console.log('Received values of form: ', values) ;
						this.doLogin();
					}
				});
			},
		},
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>